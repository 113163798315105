var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"cols":"9"}},[_c('form-wizard',{staticClass:"wizard-vertical mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"layout":"vertical","finish-button-text":"Add Another User","back-button-text":"Previous","start-index":0},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{ref:"AccessTab",attrs:{"title":"Access","before-change":_vm.processAccesTab}},[_c('validation-observer',{ref:"rulesAccesTab"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Access ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Enter The Access Details. ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"First Name *","label-for":"firstname"}},[_c('validation-provider',{attrs:{"name":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstname","placeholder":"John"},model:{value:(_vm.forename),callback:function ($$v) {_vm.forename=$$v},expression:"forename"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Last Name *","label-for":"lastname"}},[_c('validation-provider',{attrs:{"name":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","placeholder":"Doe"},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email *","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","placeholder":"john.doe@email.com"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"switch":""},model:{value:(_vm.is_email_verified),callback:function ($$v) {_vm.is_email_verified=$$v},expression:"is_email_verified"}},[_vm._v(" Make email verified ")])],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Account Type *","label-for":"account_type"}},[_c('b-form-select',{attrs:{"id":"account_type","options":['Client', 'Affiliate', 'Moderator', 'Finance']},model:{value:(_vm.accType),callback:function ($$v) {_vm.accType=$$v},expression:"accType"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Password *","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","type":"password","placeholder":"Minimum of 6 characters","vid":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Confirm Password *","label-for":"confirmpassword"}},[_c('validation-provider',{attrs:{"name":"confirmpassword","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"confirmpassword","type":"password","placeholder":"Re-type Password"},model:{value:(_vm.confirmpassword),callback:function ($$v) {_vm.confirmpassword=$$v},expression:"confirmpassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{ref:"DetailsTab",attrs:{"title":"Details","before-change":_vm.processDetailsTab}},[_c('validation-observer',{ref:"rulesProcessTab"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Details ")]),_c('small',{staticClass:"text-muted"},[_vm._v("NOTE: For EU-based companies, please provide the VAT #. For any other companies, please provide the Company Registration #.")])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Please select option below:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"radio-group-1","options":_vm.copersonOption,"aria-describedby":ariaDescribedby,"name":"radio-options-1"},on:{"change":_vm.changeCompanyIndividual},model:{value:(_vm.companyIndividual),callback:function ($$v) {_vm.companyIndividual=$$v},expression:"companyIndividual"}})]}}])}),_c('hr')],1)],1),(_vm.companyIndividual)?_c('b-row',{ref:"showCompany"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Company Name *","label-for":"company"}},[_c('validation-provider',{ref:"companyRule",attrs:{"name":"company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company","placeholder":"My Company Ltd."},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,751142152)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"VAT/Reg # *","label-for":"vatno"}},[_c('validation-provider',{attrs:{"name":"vatno","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"vatno","placeholder":"VAT/Reg Number"},on:{"blur":_vm.validateVAT},model:{value:(_vm.vatno),callback:function ($$v) {_vm.vatno=$$v},expression:"vatno"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"success","title":"Validate VAT. NOTE: For EU-based companies, please provide the VAT #. For any other companies, please provide the Company Registration #."},on:{"click":_vm.validateVATApply}},[_c('feather-icon',{attrs:{"icon":"SearchIcon","size":"14","title":"Validate VAT. NOTE: For EU-based companies, please provide the VAT #. For any other companies, please provide the Company Registration #."}})],1)],1)],1),_c('small',{staticClass:"text-danger",staticStyle:{"margin-top":"-10px"}},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3690086174)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Website","label-for":"website"}},[_c('b-form-input',{attrs:{"id":"website","placeholder":"http://mycompany.com"},model:{value:(_vm.website),callback:function ($$v) {_vm.website=$$v},expression:"website"}})],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Country *","label-for":"lcountry"}},[_c('validation-provider',{attrs:{"name":"selectedCountry","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"lcountry","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.countries,"label":"name"},on:{"input":_vm.setSelectedCountryCode},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Address 1 *","label-for":"address1"}},[_c('validation-provider',{attrs:{"name":"address1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address1","placeholder":"Address 1"},model:{value:(_vm.address1),callback:function ($$v) {_vm.address1=$$v},expression:"address1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"City / Location *","label-for":"city"}},[_c('validation-provider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city","placeholder":"City / Location"},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Post Code *","label-for":"postcode"}},[_c('validation-provider',{attrs:{"name":"postcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"postcode","placeholder":"Post Code"},model:{value:(_vm.postcode),callback:function ($$v) {_vm.postcode=$$v},expression:"postcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Address 2","label-for":"address2"}},[_c('b-form-input',{attrs:{"id":"address2","placeholder":"Address 2 (optional)"},model:{value:(_vm.address2),callback:function ($$v) {_vm.address2=$$v},expression:"address2"}})],1)],1)],1)],1)],1),_c('tab-content',{ref:"AccountTab",attrs:{"title":"Account","before-change":_vm.processAccountTab}},[_c('validation-observer',{ref:"rulesAccountTab"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Account ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Final steps for the registration.")])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"package","label":"Package *"}},[_c('validation-provider',{attrs:{"name":"selectedPackage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"package","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.packageOption,"label":"name"},on:{"input":_vm.setSelectedPackage},model:{value:(_vm.selectedPackage),callback:function ($$v) {_vm.selectedPackage=$$v},expression:"selectedPackage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Phone #","label-for":"phone"}},[_c('b-form-input',{attrs:{"id":"phone","placeholder":"+81 Phone Number"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('b-form-group',{attrs:{"label":"Skype ID","label-for":"skype"}},[_c('b-form-input',{attrs:{"id":"skype","placeholder":"Your Skype ID"},model:{value:(_vm.skype),callback:function ($$v) {_vm.skype=$$v},expression:"skype"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('h5',{staticClass:"mb-2"},[_vm._v(" Package Details ")]),_c('p',[_c('small',[_vm._v("PACKAGE CHOICE")])]),_c('new-package',{attrs:{"selected-package":_vm.selectedPackageAllFields}}),_c('hr'),_c('b-form-group',{attrs:{"label":"Subscribe to the news letter","label-for":"subscribe"}},[_c('b-form-checkbox',{attrs:{"id":"subscribe","switch":""},model:{value:(_vm.subscribe),callback:function ($$v) {_vm.subscribe=$$v},expression:"subscribe"}})],1)],1)],1)],1)],1),_c('tab-content',{ref:"FinishTab",attrs:{"title":"Completed"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('center',[_c('p',[_vm._v("An activation is sent. It contains a message with an ACCOUNT ACTIVATION button/link to "+_vm._s(_vm.emailedto)+".")]),_c('br'),_c('p',[_c('b',[_vm._v("Note:")]),_vm._v(" Use the 'Previous' button if you want to update the user's information. "),_c('span',{directives:[{name:"f",rawName:"v-f",value:(_vm.id),expression:"id"}]},[_vm._v("Or, "),_c('a',{attrs:{"href":"#"},on:{"click":_vm.impersonate}},[_vm._v("click here to impersonate.")])])])])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }